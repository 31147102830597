import React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/seo/seo"
import styled from "styled-components"
import PageHeader from "../components/pageHeader/PageHeader"
import ReservationMid from "../pagesParts/reservation/ReservationMid"
import ReservationBottom from "../pagesParts/reservation/ReservationBottom"

const StyledMainWrapper = styled.main`
  margin-top: 60px;
  background: ${props => props.theme.colors.light};
`

export default function OurMenuPage() {
  return (
    <Layout>
      <SEO
        title="Reservation"
        description="Nous sommes disponibles du lundi au vendredi entre 16h30 et 18h00 pour vous rencontrer. 
        Bloquez simplement le jour qui vous convient et nous organiserons une vidéoconférence."
      />
      <StyledMainWrapper>
        <PageHeader
          heading="Reservation"
          subheading="Nous sommes disponibles du lundi au vendredi entre 16h30 et 18h00 pour vous rencontrer. 
          Bloquez simplement le jour qui vous convient et nous organiserons une vidéoconférence ou une visite."
        />
        <ReservationMid />
        <ReservationBottom />
      </StyledMainWrapper>
    </Layout>
  )
}
